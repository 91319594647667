*,
::after,
::before {
  box-sizing: border-box;
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap');

html {
  font-size: 100%;
} /*16px*/

:root {
  /* colors */
  --primary-1: #caf0f8;
  --primary-2: #ade8f4;
  --primary-3: #90e0ef;
  --primary-4: #48cae4;
  --primary-5: #00b4d8;
  --primary-6: #0096c7;
  --primary-7: #0077b6;
  --primary-8: #023e8a;
  --primary-9: #03045e;

  /* grey */
  --grey-05: #f8fafc;
  --grey-1: #f1f5f9;
  --grey-2: #e2e8f0;
  --grey-3: #cbd5e1;
  --grey-4: #94a3b8;
  --grey-5: #64748b;
  --grey-6: #475569;
  --grey-7: #334155;
  --grey-8: #1e293b;
  --grey-9: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */

  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-05);
  --textColor: var(--grey-9);
  --radius: 0.25rem;
  --letterSpacing: 1px;
  --max-width: 1120px;
  --fixed-width: 600px;
  /* transition*/
  --transition: 0.3s ease-in-out all;
  --transition-1: 0.6s ease-in-out all;
  --transition-2: 1s ease-in-out all;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 1rem;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 2rem;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  /* width: 250px;
  height: 200px; */
  max-width: 100%;
  display: block;
  object-fit: cover;
}
.img-container {
  /* max-width: 300px;
  max-height: 300px;
  margin: 0 auto; */
  overflow: hidden;
}

/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-5);
  border: transparent;
  border-radius: var(--radius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}

.btn:hover {
  background: var(--primary-7);
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--primary-5);
  background: var(--primary-2);
}
.btn-hipster:hover {
  color: var(--primary-2);
  background: var(--primary-7);
}
.btn-block {
  width: 100%;
}
.btn-container {
  text-align: center;
}
/* table */
table {
  width: 100%;
}
table,
tr,
th,
td {
  border: 2px solid black;

  border-collapse: collapse;
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: 400px;
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 2rem auto;
}
.form-label {
  display: block;
  font-size: var(--small-text);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-2);
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-4);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.form h5 {
  text-align: center;
}
.form .btn {
  margin-top: 0.5rem;
}
.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-4);
  border-radius: 50%;
  border-top-color: var(--primary-5);
  animation: spinner 0.6s linear infinite;
}
.loading {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-5);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.section {
  padding: 2rem 0;
  width: 90vw;
  max-width: var(--max-width);
  margin: 0 auto;
}

.active {
  transition: var(--transition);
}
.hero-image {
  /* background-image: url('../src/images/hero.jpg'); */
  background-color: #cccccc;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.fixed-bg {
  /* background-image: url('../src/images/home2.jpg'); */
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Modal hidden default */

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: var(--white);
  width: 80vw;
  max-width: 400px;
  border-radius: var(--radius);
  padding: 2rem 1rem;
  text-align: center;
}
.boxDesign {
  max-width: var(--mobile-width);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  color: var(--white);
  padding: 1rem;
  margin: 1rem;
  background: linear-gradient(88deg, #0096c7 0%, #00b4d8 50%, #48cae4 100%);
}
.boxDesign:hover {
  box-shadow: var(--shadow-3);
}

.map-container {
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 992px) {
  .map-container {
    height: 50vh;
  }
}
